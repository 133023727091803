var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "1000" } },
    [
      _c(
        "v-card",
        { staticClass: "fill", attrs: { height: "800" } },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "accent",
              attrs: { flat: "", color: "main", dark: "" },
            },
            [
              _c("v-toolbar-title", [
                _vm._v(" " + _vm._s(_vm.selectedBatch.name) + " Notes "),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$emit("update:value", false)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-6" },
            [
              _c(
                "v-container",
                {
                  staticClass: "pa-4",
                  staticStyle: {
                    border: "1px solid grey",
                    "border-radius": "5px",
                    height: "500px",
                    "overflow-y": "auto",
                  },
                },
                [
                  _vm.notes.length === 0
                    ? _c(
                        "v-card",
                        { staticClass: "ma-2 pa-4", attrs: { outlined: "" } },
                        [
                          _c("v-card-text", { staticClass: "pa-8" }, [
                            _c("p", { staticClass: "black--text" }, [
                              _vm._v("No Notes Available"),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.notes, function (note, index) {
                    return _c(
                      "v-card",
                      {
                        key: index,
                        staticClass: "ma-2 pa-4",
                        attrs: { outlined: "" },
                      },
                      [
                        _c("v-card-title", { staticClass: "text-h6" }, [
                          _vm._v(" " + _vm._s(note.created_by) + " "),
                        ]),
                        _c("v-card-text", { staticClass: "pa-8" }, [
                          _c("p", { staticClass: "black--text" }, [
                            _vm._v(_vm._s(note.note_text)),
                          ]),
                        ]),
                        _c(
                          "v-card-actions",
                          { staticClass: "card" },
                          [
                            _c("small", [
                              _vm._v(
                                "BATCH " + _vm._s(note.note_type) + " NOTE"
                              ),
                            ]),
                            _c("v-spacer"),
                            _c("small", [
                              _vm._v(
                                _vm._s(_vm.formatDateTime(note.created_on))
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }